"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _commontypes = require('common-types');
var _ = require('..');
var _theme = require('../../theme');
var _reactintl = require('react-intl');
var _icons = require('../../icons');
var _constants = require('../../theme/constants');
var _customersatisfaction = require('../customer-satisfaction'); var _customersatisfaction2 = _interopRequireDefault(_customersatisfaction);
var _capitalize = require('lodash/capitalize'); var _capitalize2 = _interopRequireDefault(_capitalize);
var _emojis = require('../../emojis');
var _currency = require('../../common/utils/currency');
const StyledOverlay = _styledcomponents2.default.div`
  ${(_ref) => {
  let {
    theme: {
      spacing
    },
    $isAbsolute
  } = _ref;
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing(6)};
    margin: 0 auto;
    border-radius: inherit;
    width: ${spacing(87.5)};
    ${$isAbsolute && `padding: ${spacing(6)};`}
  `;
}}
`;
const StyledTitleContainer = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs({
  flexDirection: "column"
})`
  text-align: center;
`;
const StyledConfirmationContainer = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs({
  alignSelf: "stretch",
  justifyContent: "center",
  alignItems: "center"
})``;
const StyledProgressContent = _styledcomponents2.default.div`
  position: absolute;
`;
const StyledMinButonContainer = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs({
  flexDirection: "column",
  alignSelf: "stretch",
  justifyContent: "center",
  alignItems: "center",
  gap: _constants.SPACING.call(void 0, 4)
})``;
const StyledBalanceChangesContainer = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs({
  flexDirection: "column"
})`
  ${(_ref2) => {
  let {
    theme: {
      spacing
    }
  } = _ref2;
  return `
    gap: ${spacing(4)};
    border-radius: ${spacing(1)};
    padding: ${spacing(4)};
  `;
}}
`;
const StyledBalanceChange = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs({
  alignItems: "center",
  justifyContent: "space-between"
})``;
const StyledBalanceChangeToken = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs({
  alignItems: "center"
})`
  ${(_ref3) => {
  let {
    theme: {
      spacing
    }
  } = _ref3;
  return `
  gap: ${spacing(1)};
`;
}}
`;
const StyledAmountContainer = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs({
  alignItems: "flex-end",
  flexDirection: "column"
})``;
const satisfactionOptions = [_emojis.AngryFaceEmoji, _emojis.SlightlyFrowningFaceEmoji, _emojis.NeutralFaceEmoji, _emojis.GrinningFaceWithBigEyesEmoji, _emojis.SmilingFaceWithHeartEyesEmoji].map((Emoji, i) => ({
  label: /* @__PURE__ */ _react2.default.createElement(Emoji, {
    key: i,
    size: _constants.SPACING.call(void 0, 7)
  }),
  value: i + 1
}));
const CostBalanceChange = (_ref4) => {
  let {
    cost,
    token,
    title,
    intl
  } = _ref4;
  return /* @__PURE__ */ _react2.default.createElement(StyledBalanceChange, null, /* @__PURE__ */ _react2.default.createElement(StyledBalanceChangeToken, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodyRegular"
  }, intl.formatMessage(title))), /* @__PURE__ */ _react2.default.createElement(StyledAmountContainer, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodyBold"
  }, "-", _currency.formatCurrencyAmount.call(void 0, {
    amount: cost.amount,
    token,
    intl
  }), " ", token.symbol), !!cost.amountInUSD && /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular"
  }, "$", _currency.formatUsdAmount.call(void 0, {
    amount: cost.amountInUSD,
    intl
  }))));
};
const AmountBalanceChange = (_ref5) => {
  let {
    token,
    amount,
    inflow,
    transferedTo,
    mode,
    intl,
    title,
    isLast
  } = _ref5;
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledBalanceChange, null, /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    gap: 1
  }, title && /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular"
  }, intl.formatMessage(title)), /* @__PURE__ */ _react2.default.createElement(StyledBalanceChangeToken, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: _constants.SPACING.call(void 0, 2)
    },
    variant: "bodyBold"
  }, token.icon, " ", token.symbol))), /* @__PURE__ */ _react2.default.createElement(StyledAmountContainer, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodyBold",
    color: inflow === _commontypes.TransactionEventIncomingTypes.INCOMING ? _theme.colors[mode].semantic.success.darker : _theme.colors[mode].semantic.error.darker
  }, inflow === _commontypes.TransactionEventIncomingTypes.INCOMING ? "+" : "-", _currency.formatCurrencyAmount.call(void 0, {
    amount: amount.amount,
    token,
    intl
  }), " ", token.symbol), !!amount.amountInUSD && /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    color: _theme.colors[mode].typography.typo3,
    variant: "bodySmallRegular"
  }, "$", _currency.formatUsdAmount.call(void 0, {
    amount: amount.amountInUSD,
    intl
  })), transferedTo && /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    color: _theme.colors[mode].typography.typo3,
    variant: "bodySmallRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "L+sTrb",
    defaultMessage: [{
      "type": 0,
      "value": "Transfered to: "
    }, {
      "type": 1,
      "value": "account"
    }],
    values: {
      account: `${transferedTo.slice(0, 6)}...${transferedTo.slice(-6)}`
    }
  })))), !isLast && /* @__PURE__ */ _react2.default.createElement(_.DividerBorder1, null));
};
const SuccessTransactionConfirmation = (_ref6) => {
  let {
    balanceChanges,
    gasUsed,
    feeCost,
    successTitle,
    additionalActions,
    mode,
    successSubtitle,
    receipt,
    onClickSatisfactionOption
  } = _ref6;
  const [shouldShowReceipt, setShouldShowReceipt] = _react.useState.call(void 0, false);
  const intl = _reactintl.useIntl.call(void 0, );
  const onViewReceipt = () => setShouldShowReceipt(true);
  return /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    gap: 8
  }, /* @__PURE__ */ _react2.default.createElement(_.TransactionReceipt, {
    transaction: receipt,
    open: shouldShowReceipt,
    onClose: () => setShouldShowReceipt(false)
  }), /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    justifyContent: "center",
    gap: 6
  }, /* @__PURE__ */ _react2.default.createElement(StyledConfirmationContainer, null, /* @__PURE__ */ _react2.default.createElement(_icons.SuccessCircleIcon, {
    size: "100px",
    fontSize: "inherit"
  })), /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 2
  }, /* @__PURE__ */ _react2.default.createElement(StyledTitleContainer, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "h5Bold",
    color: _theme.colors[mode].typography.typo1
  }, successTitle)), successSubtitle && /* @__PURE__ */ _react2.default.createElement(StyledTitleContainer, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular",
    color: _theme.colors[mode].typography.typo2
  }, successSubtitle))), (balanceChanges && !!balanceChanges.length || gasUsed) && /* @__PURE__ */ _react2.default.createElement(StyledBalanceChangesContainer, null, _optionalChain([balanceChanges, 'optionalAccess', _3 => _3.map, 'call', _4 => _4((balanceChange, index) => /* @__PURE__ */ _react2.default.createElement(AmountBalanceChange, _extends({
    mode,
    key: balanceChange.token.address
  }, balanceChange, {
    intl,
    isLast: index === balanceChanges.length - 1
  })))]), feeCost && /* @__PURE__ */ _react2.default.createElement(CostBalanceChange, _extends({}, feeCost, {
    intl
  })), gasUsed && /* @__PURE__ */ _react2.default.createElement(CostBalanceChange, {
    cost: gasUsed.gasUsed,
    title: _reactintl.defineMessage.call(void 0, {
      id: "uMWUL6",
      defaultMessage: [{
        "type": 0,
        "value": "Transaction cost"
      }]
    }),
    token: gasUsed.protocolToken,
    intl
  }))), /* @__PURE__ */ _react2.default.createElement(StyledMinButonContainer, null, additionalActions.map((action) => /* @__PURE__ */ _react2.default.createElement(_.Button, {
    variant: action.variant,
    key: action.label,
    color: action.color,
    onClick: action.onAction,
    fullWidth: true,
    size: "large"
  }, action.label)), /* @__PURE__ */ _react2.default.createElement(_.Button, {
    variant: "outlined",
    fullWidth: true,
    onClick: onViewReceipt,
    size: "large",
    disabled: !receipt
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "YFISNq",
    defaultMessage: [{
      "type": 0,
      "value": "View receipt"
    }]
  }))), /* @__PURE__ */ _react2.default.createElement(_.DividerBorder1, null), /* @__PURE__ */ _react2.default.createElement(_customersatisfaction2.default, {
    mainQuestion: intl.formatMessage(_reactintl.defineMessage.call(void 0, {
      id: "FELIJv",
      defaultMessage: [{
        "type": 0,
        "value": "How satisfied are you with the "
      }, {
        "type": 1,
        "value": "operation"
      }, {
        "type": 0,
        "value": " process you just completed?"
      }]
    }), {
      operation: receipt ? _capitalize2.default.call(void 0, intl.formatMessage(_.getTransactionTypeTitle.call(void 0, receipt))) : ""
    }),
    ratingDescriptors: [intl.formatMessage(_reactintl.defineMessage.call(void 0, {
      id: "WBzb5e",
      defaultMessage: [{
        "type": 0,
        "value": "Very Frustrated"
      }]
    })), intl.formatMessage(_reactintl.defineMessage.call(void 0, {
      id: "m1BnEz",
      defaultMessage: [{
        "type": 0,
        "value": "Very Pleased"
      }]
    }))],
    onClickOption: onClickSatisfactionOption,
    options: satisfactionOptions
  }));
};
const PendingTransactionConfirmation = (_ref7) => {
  let {
    onGoToEtherscan,
    mode,
    loadingSubtitle,
    loadingTitle,
    maxWaitingTime
  } = _ref7;
  const [timer, setTimer] = _react.useState.call(void 0, maxWaitingTime);
  const timerRef = _react.useRef.call(void 0, null);
  _react.useEffect.call(void 0, () => {
    if (timer > 0) {
      timerRef.current = setTimeout(() => setTimer(timer - 1), 1e3);
    }
  }, [timer]);
  const minutes = Math.floor(timer / 60);
  const seconds = timer - minutes * 60;
  return /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    gap: 6
  }, /* @__PURE__ */ _react2.default.createElement(StyledConfirmationContainer, null, /* @__PURE__ */ _react2.default.createElement("svg", {
    width: 0,
    height: 0
  }, /* @__PURE__ */ _react2.default.createElement("linearGradient", {
    id: "progressGradient",
    gradientTransform: "rotate(90)"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "0%",
    stopColor: _theme.colors[mode].semantic.success.light
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "123.4%",
    stopColor: _theme.colors[mode].semantic.success.darker
  }))), /* @__PURE__ */ _react2.default.createElement(_.CircularProgress, {
    size: 232,
    variant: "determinate",
    value: 100,
    thickness: 4,
    sx: {
      position: "absolute",
      [`& .${_.circularProgressClasses.circle}`]: {
        strokeLinecap: "round",
        stroke: _theme.colors[mode].background.tertiary
      }
    }
  }), /* @__PURE__ */ _react2.default.createElement(_.CircularProgress, {
    size: 232,
    variant: "determinate",
    value: (1 - timer / maxWaitingTime) * 100,
    thickness: 4,
    sx: {
      [`& .${_.circularProgressClasses.circle}`]: {
        strokeLinecap: "round",
        stroke: "url('#progressGradient')"
      }
    }
  }), /* @__PURE__ */ _react2.default.createElement(StyledProgressContent, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: timer === 0 ? "h5Bold" : "confirmationLoading"
  }, timer > 0 && `${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`, timer === 0 && /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "xTu77D",
    defaultMessage: [{
      "type": 0,
      "value": "Processing"
    }]
  })))), /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 2
  }, loadingTitle && /* @__PURE__ */ _react2.default.createElement(StyledTitleContainer, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "h5Bold"
  }, loadingTitle)), /* @__PURE__ */ _react2.default.createElement(StyledTitleContainer, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "8IeSdh",
    defaultMessage: [{
      "type": 8,
      "value": "b",
      "children": [{
        "type": 1,
        "value": "loadingSubtitle"
      }]
    }, {
      "type": 0,
      "value": ". You can view the transaction state in your activity log."
    }],
    values: {
      loadingSubtitle: loadingSubtitle || "",
      b: (chunks) => /* @__PURE__ */ _react2.default.createElement("b", null, chunks)
    }
  })))), /* @__PURE__ */ _react2.default.createElement(StyledMinButonContainer, null, /* @__PURE__ */ _react2.default.createElement(_.Button, {
    variant: "outlined",
    fullWidth: true,
    onClick: onGoToEtherscan,
    size: "large"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "O+yqTs",
    defaultMessage: [{
      "type": 0,
      "value": "View in explorer"
    }]
  }))));
};
const StyledContainer = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs({
  gap: 10,
  fullWidth: true,
  flexDirection: "column"
})`
  ${(_ref8) => {
  let {
    $isAbsolute,
    theme: {
      palette: {
        mode
      },
      spacing
    }
  } = _ref8;
  return $isAbsolute && `
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: ${_theme.colors[mode].background.quarteryNoAlpha};
      border-radius: ${spacing(4)};
      right: 0;
    `;
}}
`;
const TransactionConfirmation = (_ref9) => {
  let {
    shouldShow,
    success,
    onGoToEtherscan,
    balanceChanges,
    gasUsed,
    feeCost,
    successTitle,
    additionalActions,
    successSubtitle,
    receipt,
    onClickSatisfactionOption,
    loadingSubtitle,
    loadingTitle,
    setHeight,
    maxWaitingTime
  } = _ref9;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  const innerRef = _react.useRef.call(void 0, null);
  _react.useEffect.call(void 0, () => {
    if (setHeight && shouldShow) {
      setHeight(_optionalChain([innerRef, 'access', _5 => _5.current, 'optionalAccess', _6 => _6.offsetHeight]));
    }
  });
  const handleResize = _react.useCallback.call(void 0, () => {
    if (setHeight)
      setHeight(_optionalChain([innerRef, 'access', _7 => _7.current, 'optionalAccess', _8 => _8.offsetHeight]));
  }, [setHeight]);
  const handleExit = _react.useCallback.call(void 0, () => {
    if (setHeight)
      setHeight(void 0);
  }, [setHeight]);
  return /* @__PURE__ */ _react2.default.createElement(_.Slide, {
    direction: "up",
    in: shouldShow,
    mountOnEnter: true,
    unmountOnExit: true,
    onExit: handleExit,
    onEnter: handleResize
  }, /* @__PURE__ */ _react2.default.createElement(StyledContainer, {
    $isAbsolute: !!setHeight
  }, /* @__PURE__ */ _react2.default.createElement(StyledOverlay, {
    $isAbsolute: !!setHeight,
    ref: innerRef
  }, success ? /* @__PURE__ */ _react2.default.createElement(SuccessTransactionConfirmation, {
    mode,
    balanceChanges,
    gasUsed,
    successTitle,
    additionalActions,
    successSubtitle,
    receipt,
    onClickSatisfactionOption,
    feeCost
  }) : /* @__PURE__ */ _react2.default.createElement(PendingTransactionConfirmation, {
    loadingSubtitle,
    loadingTitle,
    onGoToEtherscan,
    mode,
    maxWaitingTime
  }))));
};



exports.TransactionConfirmation = TransactionConfirmation; exports.satisfactionOptions = satisfactionOptions;
