"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _components = require('../components');
var _ = require('.');
const AnimatedArrowRightLight = _styledcomponents2.default.call(void 0, (props) => /* @__PURE__ */ _react2.default.createElement(_components.ContainerBox, {
  alignItems: "center",
  flex: 1,
  justifyContent: "center"
}, /* @__PURE__ */ _react2.default.createElement(_.ArrowRightLightIcon, _extends({}, props, {
  sx: {
    flex: "1"
  }
}))))`
  ${(_ref) => {
  let {
    $hovered,
    theme: {
      spacing
    }
  } = _ref;
  return `
    transition: transform 0.15s ease;
    transform: translateX(${$hovered ? spacing(1) : 0});
  `;
}}
`;
var animated_arrow_right_default = AnimatedArrowRightLight;


exports.default = animated_arrow_right_default;
