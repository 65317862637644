"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _ = require('..');
var _theme = require('../../theme');
var _reactintl = require('react-intl');
var _luxon = require('luxon');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _orderBy = require('lodash/orderBy'); var _orderBy2 = _interopRequireDefault(_orderBy);
var _flatten = require('lodash/flatten'); var _flatten2 = _interopRequireDefault(_flatten);
var _emojis = require('../../emojis');
const StyledContainerBoxWithHeight = _styledcomponents2.default.div`
  min-height: ${(_ref) => {
  let {
    minHeight,
    height
  } = _ref;
  return minHeight || height;
}}px;
  ${(_ref2) => {
  let {
    height
  } = _ref2;
  return height ? ` height: ${height}px;` : "flex: 1;";
}}
  color: black;
  .recharts-surface {
    overflow: visible;
  }
`;
const GraphNoDataAvailable = () => {
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    gap: 2,
    fullWidth: true,
    alignItems: "center",
    justifyContent: "center"
  }, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "h4Bold"
  }, /* @__PURE__ */ _react2.default.createElement(_emojis.ChartEmoji, null)), /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "h5Bold",
    color: _theme.colors[mode].typography.typo3
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "EKD3eV",
    defaultMessage: [{
      "type": 0,
      "value": "We could not retrieve the data for this graph"
    }]
  })));
};
const LegendItem = (_ref3) => {
  let {
    legend: {
      color,
      label
    }
  } = _ref3;
  return /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    gap: 2,
    alignItems: "center",
    justifyContent: "center"
  }, /* @__PURE__ */ _react2.default.createElement(_.ColorCircle, {
    color,
    size: 3
  }), /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular",
    color: (_ref4) => {
      let {
        palette: {
          mode
        }
      } = _ref4;
      return _theme.colors[mode].typography.typo3;
    }
  }, label));
};
function getMaxAndMin(data) {
  const values = data.map((d) => d.timestamp);
  return {
    max: Math.max(...values),
    min: Math.min(...values)
  };
}
function getAmountOfDaysBetweenDates(startDate, endDate) {
  return _luxon.DateTime.fromSeconds(endDate).diff(_luxon.DateTime.fromSeconds(startDate), "days").days;
}
let AvailableDatePeriods = /* @__PURE__ */ function(AvailableDatePeriods2) {
  AvailableDatePeriods2["day"] = "1d";
  AvailableDatePeriods2["week"] = "1w";
  AvailableDatePeriods2["month"] = "1m";
  AvailableDatePeriods2["year"] = "1y";
  AvailableDatePeriods2["all"] = "all";
  return AvailableDatePeriods2;
}({});
const DAYS_BACK_MAP = {
  [AvailableDatePeriods.day]: 3,
  [AvailableDatePeriods.week]: 7,
  [AvailableDatePeriods.month]: 30,
  [AvailableDatePeriods.year]: 365,
  [AvailableDatePeriods.all]: Infinity
};
const AVAILABLE_DATE_PERIODS_STRING_MAP = {
  [AvailableDatePeriods.day]: _reactintl.defineMessage.call(void 0, {
    id: "U0YDKB",
    defaultMessage: [{
      "type": 0,
      "value": "1D"
    }]
  }),
  [AvailableDatePeriods.week]: _reactintl.defineMessage.call(void 0, {
    id: "FcBTM2",
    defaultMessage: [{
      "type": 0,
      "value": "1W"
    }]
  }),
  [AvailableDatePeriods.month]: _reactintl.defineMessage.call(void 0, {
    id: "G8pKo7",
    defaultMessage: [{
      "type": 0,
      "value": "1M"
    }]
  }),
  [AvailableDatePeriods.year]: _reactintl.defineMessage.call(void 0, {
    id: "OTdxAW",
    defaultMessage: [{
      "type": 0,
      "value": "1Y"
    }]
  }),
  [AvailableDatePeriods.all]: _reactintl.defineMessage.call(void 0, {
    id: "uRibf4",
    defaultMessage: [{
      "type": 0,
      "value": "All"
    }]
  })
};
function getEnabledPeriods(amountOfDaysBetween) {
  const enabledPeriods = [];
  if (amountOfDaysBetween > 1) {
    enabledPeriods.push(AvailableDatePeriods.day);
  }
  if (amountOfDaysBetween > 7) {
    enabledPeriods.push(AvailableDatePeriods.week);
  }
  if (amountOfDaysBetween > 30) {
    enabledPeriods.push(AvailableDatePeriods.month);
  }
  if (amountOfDaysBetween > 365) {
    enabledPeriods.push(AvailableDatePeriods.year);
  }
  enabledPeriods.push(AvailableDatePeriods.all);
  return enabledPeriods;
}
const getEnabledDates = (data) => {
  const {
    max,
    min
  } = getMaxAndMin(data);
  const amountOfDaysBetween = getAmountOfDaysBetweenDates(min, max);
  return getEnabledPeriods(amountOfDaysBetween);
};
function addOrganicVariation(data, keys) {
  let variationFactor = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0.3;
  const itemsWithoutData = data.filter((dataPoint) => !keys.some((key) => typeof dataPoint[key] === "number"));
  const organicItems = keys.map((key) => {
    const itemsWithData = data.filter((dataPoint) => key in dataPoint && typeof dataPoint[key] === "number");
    let highestValue = -Infinity;
    const lastPointValue = _optionalChain([itemsWithData, 'access', _3 => _3[itemsWithData.length - 1], 'optionalAccess', _4 => _4[key]]);
    const firstPointValue = _optionalChain([itemsWithData, 'access', _5 => _5[0], 'optionalAccess', _6 => _6[key]]);
    const organicEstReturns = itemsWithData.map((dataPoint, index, arr) => {
      const currentValue = dataPoint[key];
      if (index === 0) {
        if (typeof currentValue === "number") {
          highestValue = currentValue;
        }
        return dataPoint;
      }
      if (index === arr.length - 1)
        return dataPoint;
      if (typeof currentValue !== "number" || typeof lastPointValue !== "number" || typeof firstPointValue !== "number") {
        return dataPoint;
      }
      const position = index / (arr.length - 1);
      const randomCurve = Math.pow(position, 1 + Math.random());
      const baseValue = firstPointValue + (lastPointValue - firstPointValue) * randomCurve;
      const variationRange = (lastPointValue - firstPointValue) * variationFactor;
      const randomVariation = Math.random() * variationRange * (1 - Math.pow(position, 0.5));
      const newValue = Math.min(lastPointValue, baseValue + randomVariation);
      const boundedValue = Math.max(highestValue, newValue);
      highestValue = boundedValue;
      return {
        ...dataPoint,
        [key]: boundedValue
      };
    });
    return organicEstReturns;
  });
  return _orderBy2.default.call(void 0, [...itemsWithoutData, ..._flatten2.default.call(void 0, organicItems)], "timestamp", "asc");
}
const GraphContainer = (_ref5) => {
  let {
    children,
    title,
    legend,
    data,
    minHeight,
    height,
    addOrganicGrowthTo,
    variationFactor,
    isLoading,
    LoadingSkeleton,
    defaultPeriod,
    defaultEnabledPeriods,
    updatePeriodCallback,
    customDaysBackMap
  } = _ref5;
  const intl = _reactintl.useIntl.call(void 0, );
  const [periodSetByUser, setPeriodSetByUser] = _react.useState.call(void 0, false);
  const [activePeriod, setActivePeriod] = _react.useState.call(void 0, defaultPeriod || AvailableDatePeriods.all);
  const enabledPeriods = _react.useMemo.call(void 0, () => defaultEnabledPeriods || getEnabledDates(data), [data, defaultEnabledPeriods]);
  const handlePeriodChange = (period) => {
    setActivePeriod(period);
    if (updatePeriodCallback) {
      updatePeriodCallback(period);
    }
  };
  _react.useEffect.call(void 0, () => {
    if (periodSetByUser) {
      return;
    }
    if (!defaultPeriod) {
      handlePeriodChange(enabledPeriods[enabledPeriods.length - 1]);
    }
    setPeriodSetByUser(true);
  }, [enabledPeriods, periodSetByUser]);
  const filteredData = _react.useMemo.call(void 0, () => {
    const today = Math.floor(Date.now() / 1e3);
    const daysBackMapValue = _optionalChain([customDaysBackMap, 'optionalAccess', _7 => _7[activePeriod]]) || DAYS_BACK_MAP[activePeriod];
    const filteredCurrentData = data.filter((d) => {
      const daysBetween = getAmountOfDaysBetweenDates(d.timestamp, today);
      return daysBetween < daysBackMapValue && daysBetween >= 0;
    });
    const amountOfDataToAdd = Math.ceil(filteredCurrentData.length / 4);
    const filteredFutureData = data.filter((d) => {
      const daysBetween = getAmountOfDaysBetweenDates(d.timestamp, today);
      return daysBetween <= 0 && daysBetween * -1 <= daysBackMapValue;
    }).slice(0, amountOfDataToAdd);
    let parsedData = _orderBy2.default.call(void 0, [...filteredCurrentData, ...filteredFutureData], "timestamp", "asc");
    if (addOrganicGrowthTo) {
      parsedData = addOrganicVariation(parsedData, addOrganicGrowthTo, variationFactor);
    }
    return parsedData;
  }, [data, activePeriod, customDaysBackMap]);
  if (isLoading && LoadingSkeleton) {
    return /* @__PURE__ */ _react2.default.createElement(LoadingSkeleton, null);
  }
  if (!isLoading && !data.length) {
    return /* @__PURE__ */ _react2.default.createElement(GraphNoDataAvailable, null);
  }
  return /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    alignItems: "stretch",
    gap: 6,
    flex: 1,
    style: {
      height: "100%"
    }
  }, (title || !!_optionalChain([legend, 'optionalAccess', _8 => _8.length])) && /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    justifyContent: "space-between"
  }, title && /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "h5Bold",
    color: (_ref6) => {
      let {
        palette: {
          mode
        }
      } = _ref6;
      return _theme.colors[mode].typography.typo1;
    }
  }, title), !!_optionalChain([legend, 'optionalAccess', _9 => _9.length]) && /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    gap: 2
  }, legend.map((legendItem) => /* @__PURE__ */ _react2.default.createElement(LegendItem, {
    key: legendItem.color,
    legend: legendItem
  })))), /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    gap: 3,
    flex: 1
  }, /* @__PURE__ */ _react2.default.createElement(StyledContainerBoxWithHeight, {
    minHeight,
    height
  }, children(filteredData)), /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    justifyContent: "flex-end",
    gap: 1,
    alignItems: "center"
  }, enabledPeriods.map((period) => /* @__PURE__ */ _react2.default.createElement(_.Button, {
    key: period,
    variant: "contained",
    color: period === activePeriod ? "primary" : "secondary",
    size: "small",
    sx: (_ref7) => {
      let {
        spacing
      } = _ref7;
      return {
        padding: `${spacing(1)} ${spacing(2)}`
      };
    },
    onClick: () => handlePeriodChange(period)
  }, intl.formatMessage(AVAILABLE_DATE_PERIODS_STRING_MAP[period]))))));
};





exports.AvailableDatePeriods = AvailableDatePeriods; exports.GraphContainer = GraphContainer; exports.GraphContainerPeriods = AvailableDatePeriods; exports.GraphNoDataAvailable = GraphNoDataAvailable;
