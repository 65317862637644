"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function KeyIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "32px/",
    d: "M6.18001 23.2499C6.08001 23.2499 5.97002 23.2399 5.88001 23.2299L3.71001 22.9299C2.67001 22.7899 1.73001 21.8599 1.57001 20.7999L1.27001 18.6099C1.17001 17.9099 1.47001 16.9999 1.97001 16.4899L6.36001 12.0999C5.65001 9.25992 6.47002 6.25992 8.56002 4.18992C11.8 0.959923 17.07 0.949923 20.32 4.18992C21.89 5.75992 22.75 7.84992 22.75 10.0699C22.75 12.2899 21.89 14.3799 20.32 15.9499C18.22 18.0299 15.23 18.8499 12.41 18.1299L8.01002 22.5199C7.59001 22.9599 6.84001 23.2499 6.18001 23.2499ZM14.43 3.25992C12.68 3.25992 10.94 3.91992 9.61001 5.24992C7.81001 7.03992 7.16001 9.65992 7.91001 12.0999C7.99001 12.3699 7.92001 12.6499 7.72001 12.8499L3.02001 17.5499C2.85001 17.7199 2.71001 18.1599 2.74001 18.3899L3.04001 20.5799C3.10001 20.9599 3.51002 21.3899 3.89001 21.4399L6.07001 21.7399C6.31001 21.7799 6.75001 21.6399 6.92001 21.4699L11.64 16.7599C11.84 16.5599 12.13 16.4999 12.39 16.5799C14.8 17.3399 17.43 16.6899 19.23 14.8899C20.51 13.6099 21.22 11.8899 21.22 10.0699C21.22 8.23992 20.51 6.52992 19.23 5.24992C17.93 3.92992 16.18 3.25992 14.43 3.25992Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "32px/_2",
    d: "M9.18996 21.0397C8.99996 21.0397 8.80996 20.9697 8.65996 20.8197L6.35996 18.5197C6.06996 18.2297 6.06996 17.7497 6.35996 17.4597C6.64996 17.1697 7.12996 17.1697 7.41996 17.4597L9.71996 19.7597C10.01 20.0497 10.01 20.5297 9.71996 20.8197C9.56996 20.9697 9.37996 21.0397 9.18996 21.0397Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "32px/_3",
    d: "M14.5 12.25C13.26 12.25 12.25 11.24 12.25 10C12.25 8.76 13.26 7.75 14.5 7.75C15.74 7.75 16.75 8.76 16.75 10C16.75 11.24 15.74 12.25 14.5 12.25ZM14.5 9.25C14.09 9.25 13.75 9.59 13.75 10C13.75 10.41 14.09 10.75 14.5 10.75C14.91 10.75 15.25 10.41 15.25 10C15.25 9.59 14.91 9.25 14.5 9.25Z"
  }));
}


exports.default = KeyIcon;
